html,
body {
  font-family:"Roboto", "Helvetica", "Arial",  sans-serif , 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', -apple-system, BlinkMacSystemFont,
    sans-serif;
  background-color: #181818;
  color: #fff;
  padding:0;
  margin:0;
 
}
 
.nav-container {
  margin: auto;
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}

#query:before,
#query:before{
  display: none !important;
}
#query{
  padding: 3px 4px !important;
  font-size: 1em !important;
  color: #fff!important;   
}
 

.container{
  height: 100vh;
}
.cjjWdp {
  background: #fff !important; 
}


.MuiIconButton-root{
  padding: 3px !important; 
}


/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px #3b3b3b  inset !important;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 30px #3b3b3b  inset !important;
 
}
.fXAXjb:hover,
.fXAXjb{
  width: 1px!important;
  margin: 0 1px 0 -7px!important;
  border-left: none!important;
  border-right: 1px solid rgba(255,255,255,0)!important;
}

.MuiAutocomplete-noOptions {
  padding: 10px 16px;
  font-size: 0.8em;
}

.sc-bwzfXH,
.sc-bwzfXH:hover{
  height: 1px!important;
  margin: 0 1px 0 -7px!important;
  border-bottom: none!important;
  border-top: 1px solid rgba(255,255,255,0)!important;
}
.MuiMenu-list .MuiListItem-button,
.MuiMenu-list .MuiListItem-button .MuiListItemText-primary {
  font-size: 1.2em;
}
.MuiMenu-list{
  background: #333;
}
.MuiListItem-root{
  font-size: 0.8em !important;
}
.MuiSelect-outlined.MuiSelect-outlined{
  font-size:0.75em !important;
}

.MuiMenu-list .MuiListItem-button .MuiListItemIcon-root {
  min-width: 32px !important;
}

.MuiMenu-list .MuiListItem-button{
  padding: 2px 16px ;
  min-height: 33px !important;
  /* max-width: 230px; */
  white-space: normal;
}
#menu-status .MuiMenu-list .MuiListItem-button{
  max-width: inherit;

}
.MuiSwitch-colorSecondary.Mui-checked{
  color: #1daf5c!important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color:#1daf5c!important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1daf5c!important;
}
.MuiTabs-scrollable button{
  font-size: 0.75em;
}
.MuiCheckbox-root{
  padding: 3px !important;
}
.MuiTableCell-alignCenter button{
  padding: 3px !important;
}

.MuiPickersToolbar-toolbar{
  background: #383737 !important;
  padding: 13px!important;
  min-height: 34px !important;
  height: inherit !important;
}
.MuiPickersToolbar-toolbar .MuiTypography-h4{
  font-size: 14px!important;
}
.MuiPickersCalendarHeader-switchHeader .MuiTypography-alignCenter {
  font-size: 14px!important;
}
.MuiPickersCalendar-transitionContainer .MuiPickersDay-daySelected {
  background-color: #169e4f!important;
}
.MuiDialogActions-spacing .MuiButton-textPrimary {
  color: #fff!important;
}
.MuiAutocomplete-option {
  font-size: 1em !important;
}
.MuiTab-root{
  min-width: inherit !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
/* .MuiTabs-flexContainer{
  justify-content: center !important;
} */
.MuiList-root:focus{
  outline: none;
}
.MuiSwitch-root .MuiIconButton-root{
  padding:9px !important;
}

#menu-andparam .MuiMenu-list .MuiListItem-button{
  max-width: inherit!important;
  white-space: normal!important;
}
.loader {
  height: 100%;
  width: 100%;
  text-align: center;
  position: fixed;
}
.loader .MuiCircularProgress-colorPrimary{
  color: #169e4f;
  position: absolute;
    top: 50%;
    bottom: 0;
    margin: 0 auto;

}

.MuiDropzoneArea-root{
  font-size: 12px;
  background:#1F364D !important ;
  border: none !important;
  outline:none !important;
  min-height: inherit !important;
    padding-bottom: 10px;
    border-top: 2px solid #395e84 !important;
}
.MuiDropzoneArea-root:focus,
.MuiDropzoneArea-root:active,
.MuiDropzoneArea-root:hover{
  background: #2e567e !important ;
}
.MuiDropzoneArea-root p{
  font-size: 12px!important ;
  padding: 0px 12px 10px 12px!important;
}
.dragTitle{
  font-size: 1em;
  margin-bottom: 5px;
  margin-top: 150px;
  display: block;
}
.dragDescription{
  font-size: 11px;
}
.clearanceGreen{
  color: #fff;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.MuiDropzoneArea-icon {
  width: 30px !important;
  height: 30px !important;
}
.MuiAvatar-square img{
  border-radius: 3px;
}
.MuiAvatar-square{
  background-color: #000;
}
.MuiAvatar-square img{
  width: 100% !important;
    min-width: inherit;
    height: auto;
}
.tablePointerHand{
  cursor: pointer;
}
.clIcons{
  font-size: 19px;
}
.clIconsmsg{
  position: relative;
}
.clIconsmsg .MuiBadge-colorSecondary {
  background-color: #fff!important;
  color: #000 !important;
}
.clIconsmsg .MuiBadge-dot {
  height: 18px;
  padding: 0;
  min-width: 18px;
  border-radius: 18px;
}
.clIconsmsg .MuiBadge-anchorOriginTopRightRectangle{
  top: 12px;
  left: 4px;
  width: 10px;
}
div.clIconsmsg svg,
span.clIconsmsg svg {
  font-size: 18px;
}
.MsgButton{
  margin-left: 7px;
}
.clearMe{
  color: #000;
    background: #eebc4d;
    display: inline-block;
    border-radius: 3px;
    padding: 5px;
}
input[type="date"] {
  position: relative;
  padding:"6.5px 5px 5.5px 38px !important"
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  outline: none;
}
input[type="date"]:before{
  color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f073';
    width: 15px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: 20px;
    color: #fff8f8;
    font-size: 12px;
}
.MuiChip-sizeSmall{
  background:#484848 !important; 
}
.MuiChip-sizeSmall:hover{
  background:#353535  !important; 
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #46af22 !important;
  border-width: 1px !important;
}

 
.MuiAvatar-circle.Blue.MuiAvatar-colorDefault{
  color: #000 !important;
}
.editAll{
  background: #0c853e;
  border: 1px solid #0c853e;
  padding: 6px;
  border-radius: 3px;
  color: #fff;
  margin-right: 0;
  outline: none;
  cursor: pointer;
}
.editAll:hover{
  color: #29D211;
  border: 1px solid #403f3f;
  background: #000;
}
.settingNameSpan{
  min-width: 40%;
  max-width: 40%;
}
.deleteAll{
  background: #3e3e3e;
  border: none;
  padding: 6px 6px 4px 6px;
  border-radius: 3px;
  color: #fff;
  margin-right: 0px;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  border: 1px solid #6b6b6b;
}
.deleteAll:hover{
  color: #ff5f84;
  border: 1px solid #403f3f;
  background: #000;
}
.deleteAll svg{
  color:#ff5f84;
}
.createdBy{
  display:block;
  margin: 0 !important;
}
.Green {
  border-color:#1bdeba !important;
  color:#1bdeba !important;
}
.MuiAvatar-circle.Green.MuiAvatar-colorDefault{
  background: #1bdeba;
  color: #000 !important;
}
 
.Purple {
  border-color:#43bdf7 !important;
  color:#43bdf7 !important;
}
.MuiAvatar-circle.Purple.MuiAvatar-colorDefault{
  background: #43bdf7;
  color: #000 !important;
}
.Yellow {
  border-color:#7cf9ff !important;
  color:#7cf9ff !important;
}
.MuiAvatar-circle.Yellow.MuiAvatar-colorDefault{
  background: #7cf9ff;
  color: #000 !important;
}
.MuiAvatar-colorDefault{
  background-color: #b9b9b9 !important;
  color: #121212!important;
  border-radius: 3px !important;
}

.MuiBadge-colorSecondary {
  background-color: #A8003B!important;
  color: #fff !important;
}
.Newbutton .MuiButtonBase-root{
  background: #6d6d6d;
  width: 30px;
    height: 30px;
}
.Newbutton .MuiButtonBase-root:hover{
  background: #0298a4;
}
.promoList .MuiChip-root{
  color: #FFC400;
  border: 1px solid #FFC400;
  height: inherit;
  padding: 4px 0px;
  font-size: 11px;
  background: none;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 6px;
  max-width: 160px;
}
.MuiChip-root{
  margin: 3px;
  font-size:0.7em  !important;
}

.dialogYesButton{
  background:#118543;
  border: none;
    padding: 10px;
    border-radius: 3px;
    color: #fff;
    box-shadow: 0 0 3px #464646;
    cursor: pointer;
}
  
  .dialogYesButton:hover{
    background:#000;
    color:#fff;
  }
.dialogNoButton{
  background:#505050;
  border: none;
    padding: 10px;
    border-radius: 3px;
    color: #fff;
    box-shadow: 0 0 3px #464646;
    cursor: pointer;
}
.dialogNoButton:hover{
  background:#000;
  color:#fff
}
.dialogBox.removeMaxWidth .MuiDialog-paperWidthSm{ 
  max-width: inherit;
}

.dialogBox.removeMaxWidth .rdrMonthAndYearPickers select{
  background-color: #505050;
}
.dialogBox.removeMaxWidth .rdrMonthAndYearWrapper{
  background: #3a3a3a;
    padding: 0;
    border-radius: 5px;
}
.dialogBox.removeMaxWidth .rdrDateDisplay{
  margin-right: 0;
  margin-left: 0;
  border-radius: 5px;
}
.dialogBox.removeMaxWidth .rdrDateDisplayItem {
  background: #4c4c4c !important;
}
.rdrDateDisplayItem input{
  color: #fff !important;
}

.dialogBox.removeMaxWidth .rdrMonthName{
  color: #e9ad05;
}
.dialogBox.removeMaxWidth .rdrWeekDay{
  color:rgba(255, 255, 255, 0.5)!important;
}
.dialogBox .MuiDialogTitle-root{
  padding: 13px 13px 2px 13px;
  text-align: center;
}
.dialogBox .MuiTypography-h6{
  font-size: 16px;
}

.dialogBox .MuiDialogContent-root{
  padding: 0px 24px 8px 24px;
  font-size: 0.8em;
  text-align: center;
  overflow: hidden;
}
.dialogBox .MuiTypography-body1{
  font-size: 1em;
    margin: 0;
    word-break: break-word;
}
.dialogBox button{
  font-size: 12px;

}
.dialogBox .MuiDialogActions-spacing{
  align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.dialogBox2 .MuiDialogContent-root{
  padding: 0px 24px 8px 24px;
  font-size: 0.8em;
  text-align: center;
  /* overflow: hidden; */
}
.dialogBox2 .MuiTypography-body1{
  font-size: 1em;
    margin: 0;
    word-break: break-word;
}
.dialogBox2 button{
  font-size: 12px;
}
.dialogBox2 .MuiDialogActions-spacing{
  align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.dialogBox2 .MuiDialogTitle-root{
  padding: 13px 13px 2px 13px;
  text-align: center;
}
.dialogBox2 .MuiTypography-h6{
  font-size: 16px;
}

.clearanceButtonPopup{
  width:100%;
}
.clearanceWizardFirstView{
  padding-top:32px !important;
}
.clearanceValueLabel{
  font-size: 0.8em;
  text-transform: none;
  color: #fff !important;
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100px;
    max-width: 100px;
}
.notCondition{
  top: 14px;
  left: 14px;
  width: 14px;
  border: 1px solid #000000;
  height: 3px;
  z-index: 9;
  position: absolute;
  transform: rotate(47deg);
  background: #ff4584;
  border-radius: 3px;
}

span.clearMe {
  background: #eebc4d;
  padding: 5px;
  border-radius: 3px;
  color: #000;
  text-transform: none !important;
}
.MuiTableCell-stickyHeader{
  background-color: #1f1b1b  !important;
}
.tablesLink{
  color: #26B70B;
}
.tablesLink:hover{
    color:#7bef94;
    text-decoration:underline;
  }
.Mui-error input{
  background: #9C214E!important;
  border-color: #9C214E!important;
}
.Mui-error {
  margin-bottom: 5px;
}

.MuiFormHelperText-root.Mui-error{
  margin:0;
  color:#FFD1D1 !important;
  font-size: 12px;
}
span.MuiFormHelperText-root.Mui-error{
  margin:8px 0 0 0;
  display: block;
}
.Mui-error.MuiAutocomplete-inputRoot{
  background: #9c214e !important;
}

.MuiFormHelperText-root.Mui-error.Mui-focused{
  color:#FFD1D1 !important;
 
}
.tableDataTitleCase{
  text-transform: none !important;
}
.MuiSnackbarContent-message{
  font-size: 12px;
}
/* .advancedSearchforLaptop{
  display: flex;
  width: -webkit-fill-available;
} */
.tagTypeViewPanel{
  display: block;
  padding: 9px 20px;
  background: #303030;
  margin-bottom: 14px;
  color: #FFDB2D;
  font-size: 12px;
}
.helpViewPanel{
  display: block;
  padding: 9px 20px;
  background: #585858;
  color: #ffdb2d;
  font-size: 12px;
  text-align: left;
}
.AlbumSearchforLaptop{
  display: flex;
}
.MuiTableCell-alignLeft .inlineWizardLinkArtist{
  display: block;
    font-weight: 400 !important;
    text-transform: none !important;
    color: #ccc;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.inlineWizardTitle{
  text-transform: none !important;
  font-weight: 600;
  
}

.blockForSmallDevices{
  width: auto !important;
  /* min-width: 155px !important; */
}

.albumSearchforLaptop .searchButtonsSet,
.advancedSearchforLaptop .searchButtonsSet{
  display: flex;
  align-items: center;
}
.promoSearchforLaptop .blockForSmallDevices{
  width: auto !important;
  min-width: 170px !important;
  display: flex;
  justify-content: flex-end;
}
.MuiDrawer-paperAnchorRight {
  width:calc(20% - 0.2px) !important;
    min-width: auto !important;
}
.msgDrawer  .MuiDrawer-paperAnchorRight {
  width:calc(50% - 0.2px) !important;
    min-width: auto !important;
}
.msgDrawer {
  max-width: 80%;
  margin: 0 auto;
  position: relative;

}

.newWizardDrawer .MuiDrawer-paperAnchorRight{
  width: calc(75% - 0.2px) !important;
    min-width: auto !important;
    overflow-x: hidden;
}
.newWizardDrawer{
  width: 800px;
  z-index: 33;
  position: relative;
}

.MuiCheckbox-colorPrimary.Mui-checked{
  color: #40c376 !important;
}
.appbarViewSettings{
  margin:0 8px;
}
.InnerthreeDiv{
  display: flex;
}
.InnerthreeDiv span{
  margin:0 5px;
  width: -webkit-fill-available;
}
.searchHighlighter,
.searchHighlighter div,
.searchHighlighter input{
  border-radius: 5px !important;
  background:#cead4a!important;
    color: rgb(0 0 0);
}
.searchHighlighter div .MuiInputAdornment-positionEnd{
  background:none!important;
}
.searchHighlighter button{
  padding: 10px 5px;
}
.searchHighlighter input{
  padding-right:32px !important;
}
.searchCloudHighlighter input{
  background:#0079af!important;
    color: #fff;
}

.srchHighlighter{
  background:#ffeeb9!important;
    color: rgb(0 0 0);
}


.albumSearchChips span{
    font-size: 12px;
    margin-right: 0px;
}
.albumSearchChips span strong{
  margin-top: 18px;
  display: inline-block;
}
/* .addedTagClose{
  border-radius: 50px;
  border: 1px solid #4a4949;
  background: #424242;
  color: #fff;
  vertical-align: middle;
  margin-left: 5px;;
}
.addedTagClose:hover{
  background: #383535;
  cursor: pointer;
}
.addedTagClose span{
  vertical-align: middle;
} */

.albumSearchChips .mainTagWrapper{
  width: auto !important;
  border-radius: 43px;
  min-width: auto !important;
  vertical-align: top;
  height: auto;
  padding: 3px;
  font-size: 11px;
  color:  rgb(255 200 0);
  margin-top: 10px;
  border:1px solid rgb(255 200 0);
  text-transform: capitalize;
  margin-right: 5px;
  margin-left: 5px;
}
.albumSearchChips .mainTagWrapper label{
  margin: 0 10px;
}
.albumSearchChips button{
  position: relative;
  top: inherit;
  margin: 0 ;
  padding: 0;
  width: 25px !important;
  min-width: inherit;
  height: 25px;
}
.albumSearchChips button span{
  margin: 0;
}
span.tracksUploaded{
  color:#61ecc5;
  text-transform: none!important;
}
span.tracksUploading{
  color:#f7c200;
  text-transform: none!important;
}
span.tracksUploadingError{
  color:#ff9898 ;
  text-transform: none !important;
}

span.tracksUploadingNotCompleted{
  color:#6495ED ;
  text-transform: none !important;
}
span.tracksUploadingOther{
  color:#ffffff ;
  text-transform: none !important;
}
#mm-viewPort td.MuiTableCell-alignLeft {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #000;
}
#mm-viewPort th.MuiTableCell-alignLeft {
  white-space: nowrap;
  background: #000;
}
#mm-viewPort .MuiTableCell-alignRight,
#mm-viewPort .MuiTableCell-alignCenter{
  background: #000;

}

#mm-viewPort td.MuiTableCell-alignRight,
#mm-viewPort td.MuiTableCell-alignCenter{
  background: #000;
  position: relative;
}
#mm-viewPort  .MuiTableRow-hover:active td,
#mm-viewPort  .MuiTableRow-hover:focus td,
#mm-viewPort  .MuiTableRow-hover:hover td,
#mm-viewPort  .MuiTableRow-hover:focus-within td{
/* background: rgb(76 76 76 / 62%); */
background: rgb(56 56 56);
}
#mm-viewPort  .MuiTableRow-hover:active td .MuiAvatar-square,
#mm-viewPort  .MuiTableRow-hover:focus td .MuiAvatar-square,
#mm-viewPort  .MuiTableRow-hover:hover td .MuiAvatar-square,
#mm-viewPort  .MuiTableRow-hover:focus-within td .MuiAvatar-square{
display: none;
}

#mm-viewPort  .MuiTableRow-hover:active td .albumEnable .MuiIconButton-root,
#mm-viewPort  .MuiTableRow-hover:focus td .albumEnable .MuiIconButton-root,
#mm-viewPort  .MuiTableRow-hover:hover td .albumEnable .MuiIconButton-root,
#mm-viewPort  .MuiTableRow-hover:focus-within td .albumEnable .MuiIconButton-root{
opacity: 1;
}

.MuiTablePagination-spacer{
  display: none;
}
.MuiTablePagination-caption {
  font-size: 0.85em !important;
}
.MuiTablePagination-root .MuiSelect-select.MuiSelect-select,
.MuiPagination-ul li button,
.MuiPagination-ul li{
  font-size: 12px !important;
}
.MuiTablePagination-toolbar {
  min-height: 43px !important;
  border-top: 2px solid #636363;
}
.MuiListSubheader-root{
  background-color: #333333 !important;
    color: #23d6ff !important;
    padding: 0 13px !important;
    line-height: 32px !important;
    font-size: 1em !important;
}
.tempClass{
  color: #fff!important;
  border: 1px solid #fff !important;
}

.sentToPlayout h2{
  font-weight: 400;
  font-size: 16px;
}

.sentToPlayout .MuiFormGroup-root { 
  padding: 10px 30px;
}

.sentToPlayout .MuiDialogTitle-root{
    background: #1d688c; 
    padding:10px 20px;
}
.sentToPlayout .MuiDialogTitle-root svg{
  vertical-align: middle;
  font-size: 24px;
  margin-right: 12px;
}
.sentToPlayout .MuiDialogActions-spacing button{
  margin-right: 0;
  margin-left: 10px;
}
.sentToPlayout .MuiDialog-paperWidthSm {
  max-width: 950px;
  width:950px;
  border: 1px solid #444;
}
.sentToPlayout .MuiDialogActions-root{

  padding: 12px 10px;
  border-top: 1px solid #464646;
  background: #2a2a2a;
}

.sentToPlayout table{
  font-size: 12px;  
  width: 100%;
}
.sentToPlayout table thead th{
  background: #1f1b1b;
  padding: 10px 15px;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
  font-size: 11px;
  border-bottom: 1px solid #515151;
}
.sentToPlayout  .MuiDialogContent-dividers {
  padding: 0px 0px 16px 0px;
  border:none;
  min-height: 300px;
  overflow: auto;
  background: #2a2a2a;
  max-height: 300px;
}
.sentToPlayout  .MuiDialogContent-dividers  .MuiCircularProgress-colorPrimary {
  color: green;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.sentToPlayout table tr td {
  border-bottom: 1px solid #636363;
  padding: 7px 15px;
  line-height: 1.4;
  background: #000;
}
.sentToPlayout .MuiFormControlLabel-label{
   font-size: 12px;  
}
.sentToPlayout  table tr, .sentToPlayout table td,.sentToPlayout table th{
  border:0;
}


.addTracktoClearance h2{
  font-weight: 400;
  font-size: 16px;
}
.addTracktoClearance .MuiChip-root {
  max-width: 200px;
}

.addTracktoClearance .MuiFormGroup-root { 
  padding: 10px 30px;
}

.addTracktoClearance .MuiDialogTitle-root{
    background: #1d688c; 
    padding:10px 20px;
}
.addTracktoClearance .MuiDialogTitle-root svg{
  vertical-align: middle;
  font-size: 24px;
  margin-right: 5px;
}
.addTracktoClearance .MuiDialogActions-spacing button{
  margin-right: 0;
  margin-left: 10px;
}
.addTracktoClearance .MuiDialog-paperWidthSm {
  max-width: 90%;
  width: 90%;
  max-width: 1200px;
  border: 1px solid #444;
}
.addTracktoClearance .MuiDialogActions-root{
  font-size: 1em;
  padding: 12px 10px;
  border-top: 1px solid #464646;
  background: #2a2a2a;
}

.addTracktoClearance table{
  font-size: 12px;  
  width: 100%;
}
.addTracktoClearance table thead th{
  background: #1f1b1b;
    padding: 5px 15px;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
    font-size: 11px;
    border-bottom: 1px solid #363636;
}
.addTracktoClearance  .MuiDialogContent-dividers {
  padding: 0px 0px 16px 0px;
  border:none;
  min-height: 300px;
  overflow: auto;
  background: #2a2a2a;
  max-height: 300px;
}
.addTracktoClearance  .MuiDialogContent-dividers  .MuiCircularProgress-colorPrimary {
  color: green;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.addTracktoClearance table tr td {
  border-bottom: 1px solid #363636;
  padding: 7px 15px;
  line-height: 1.4;
  background: #000;
  font-size: 1em;
}
.addTracktoClearance .MuiOutlinedInput-input{
  padding:10px;
  font-size: 0.8em;
  background: #2d2d2d;
}
.addTracktoClearance label{
  color: #DEDEDE;
  margin: 0;
  display: block;
  font-size: 0.8em;
  text-transform: uppercase;
  min-width: 120px;
}
.addTracktoClearance table label{
  min-width: inherit;
  color: #fff;
}
.addTracktoClearance  table label{
  margin: 0;
  font-size: 1em;
}
.addTracktoClearance .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #46af22 ;
}
.addTracktoClearance .MuiDialogContent-root{
  background: #000;
  padding: 20px;
  overflow: hidden;
  height: 400px;
}
.addTracktoClearance .MuiFormControlLabel-label{
   font-size: 12px;  
}
.addTracktoClearance  table tr, .addTracktoClearance table td,.addTracktoClearance table th{
  border:0;
}
.rbt-aux {
  font-size: 12px;
}
.externalLinks{
  color: #62CFFE;
  padding: 3px 5px !important;
  font-size: 0.7em !important;
  display: block;
}
a:focus-visible{
    outline: none;
    text-decoration: underline!important;
}
.termsLink{
  text-align: center;
}
.footerLinks {
  display: flex;
  justify-content: center;
}
.footerLinks .middleLinks span{
  border-left: 1px solid #606060;
  border-right: 1px solid #606060;
  padding: 0 10px;
}
.externalLinks > div{
  vertical-align:bottom;
}
.externalLinks:hover{
  color:#fff !important;     
  background: none !important; 
  text-decoration: underline;
}
 
.rbt-highlight-text{
  background: #ffeeb9 !important; 
 
}
.DiscreteIconsContainer{
  float: right;
}
.hideInactive{
  display: none;
}
.MuiTableCell-head:hover .hideInactive{
  display: inline-block;
}
.MuiTableCell-alignLeft{
  position: relative;
  border-left: none;
  border-right: none;
}
.discreteTracks{
  right: 0;
  top: 8px;
  float: right;
}
.discreteTracks span{
  font-size: 11px;
  color: #7bef94;
}
.explicitContents{
  right: 0;
  top: 9px;
  border-radius: 3px;
  margin: 0 0px  0 4px;
  padding: 1px 3px;
  /* min-width: 24px; */
  text-align: center;
  float: right;
  white-space: nowrap;
}
.overideTagstyles {
  float:none;
  display:inline-block;
  margin:0px 0 10px 0;
}
.DiscreteExplicitFlags,
.DiscreteFlag,
.ExplicitFlag {
  display:flex;
  align-items: center;
}
.DiscreteFlag strong{
  width: -webkit-fill-available;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 60%; */
}
.DiscreteFlag button{
  padding: 0 !important;
    margin: 0;
}
.DiscreteExplicitFlags strong{
  /* width: 60%; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: -webkit-fill-available;
}
.DiscreteExplicitFlags .discreteTracks {
  position: relative;
  right: 0;
  top: -2px;
  vertical-align: top;
  position: relative;
  margin: 0;
  float: right;
}
.DiscreteExplicitFlags .explicitContents {
  position: relative;
  right: 0;
  top: 0;
  border-radius: 3px;
  margin: 0;
  padding: 0  3px;
  float: right;
  margin: 0 0px 0 5px;
}
.ExplicitFlag strong{
  /* width: 80%; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: -webkit-fill-available;
}
.discreteTracks span:hover{
  text-decoration: underline;
}
#menu-tagWordSearchType  .MuiMenu-list .MuiListItem-button,
#menu-track_tagWordSearchType .MuiMenu-list .MuiListItem-button {
  justify-content: center;
  font-size: 1em;
}
 
.clearanceLivePopup {
  min-width: 450px;
  padding: 5px 0px 15px 0px;
}

.clearanceLivePopup .MuiDialogTitle-root {
  margin: 0;
  padding: 8px 24px;
  border-bottom: 1px solid #616161;
  padding-bottom: 5px;
  margin-bottom: 15px;
}
.clearanceLivePopup  .MuiDialogActions-root {
  padding-right: 15px;
}
.clearanceLivePopup .MuiCircularProgress-colorPrimary {
  color:white;
  width: 20px !important;
  height:20px !important;
}
.MuiAutocomplete-paper li{
  font-size: 12px !important;
  padding: 5px 15px!important;
  cursor: pointer;
}
.MuiAutocomplete-paper .MuiAutocomplete-loading{
  font-size: 12px !important;
}

.appbarDiscreteButton{
  min-width: inherit !important; 
    padding:8px 8px 7px  8px!important; 
    background:#0c853e  !important;
    color:#fff!important;
    margin: 0px 5px 0 5px !important;
    border: 1px solid #0c853e  !important;
}
.appbarDiscreteButton:hover{
  background: #04923F !important;
}
.appbarDiscreteDisable{
  background:none !important;
  border: 1px solid #0c853e  !important;
  min-width: inherit !important;
  color:#0fec6a  !important;
  padding: 7px 8px 8px 8px  !important;
  margin: 0px 5px 0 5px !important;
}
.appbarDiscreteDisable:hover{
  background:#0c853e  !important;
  color:#fff!important;
}
 .noDataToPreview{
  padding: 0 10px 0 18px;
  font-size: 12px;
 }
 .clearanceTrackArtist,
 .clearanceTrackDetails{
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .clearanceDropMenu{
   width:100%;
   }

   .MuiRadio-colorSecondary.Mui-checked{
     color: #11af52 !important;
   }
  

.MuiListItem-button:hover .MuiListItem-button {
    background-color: transparent;
}
.MuiAutocomplete-clearIndicatorDirty .MuiSvgIcon-fontSizeSmall{
  font-size: 1rem !important;
  padding: 2px;
}
.Huge{
font-size: 1.1em;
display: block;
margin-bottom: 5px;
}
.Normal{
  font-size: 1em;
  display: block;
  margin-bottom: 5px;
}
.Small{
  font-size: 0.8em;
  display: block;
  margin-bottom: 5px;
}
.help-paragraph{
  font-size: 1em;
  display: block;
  text-align: justify;
  margin-bottom: 15px;
}
.ql-toolbar.ql-snow{
  background: #c5c5c5;
  border-radius: 5px 5px 0 0;
}
.quill  .ql-editor,
.quill  .ql-editor.ql-blank {
  background: #2d2d2d;
}
.ql-container.ql-snow {
  border: 1px solid #6c6c6c;
  border-radius: 0 0 3px 3px;
}
button.ql-active {
  background: #efefef !important;
  border-radius: 3px;
}
.helpTitle{
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  display: inline-block;
  margin-top: 7px;
  line-height: 1;
}
.help-paragraph.ql-editor{
  padding: 8px 0;
  margin: 0;
}

.ql-toolbar{
  background: #5d5d5d;
    border-radius: 3px 3px 0 0;
    padding: 5px;
}
.ql-toolbar button{
  color: #272626;
  border: 1px solid #423e3e;
  cursor: pointer;
  text-align: center;
  padding: 3px;
  height: inherit;
  margin: 3px;
  border-radius: 3px;
  font-size: 1em;
  
}
.ql-toolbar button:hover{
  background: #423e3e;
  color: #fff;
}
.ql-toolbar button svg{
  vertical-align: top;
  font-size: 20px;
}
.ql-toolbar button.ql-active{
  background: #0f6ca9 !important;
  color: #fff;
}

.globalClearButton{
  background: none;
  border: none;
  font-size: 12px;
  color: #fff;
  width: 26px;
    height: 25px;
   
}
.globalClearButton:hover{ 
  background: #444;
  border-radius: 50%;
}
.globalClearButton svg{
  font-size: 14px;
}

.helpAddMargin{
  width: 35px;
height: 31px;
min-width: inherit !important;
border: none !important;
}

.helpTooltip{
  float: right;
  width: auto;
  min-width: inherit !important;
  font-size: 12px !important;
  position: absolute !important;
  right: 0;
}
.helpTooltip:hover{
  background: none !important;
}
.helpTooltip svg{
  font-size: 17px;
}
.searchButtonsSet .helpAddMargin{
  min-width: 34px !important;
  margin-left: 5px;
}

.albumEnable.thumbPlayer{
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;

}

.albumEnable.thumbPlayer .pUpdating{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 1px;
}
.albumEnable .MuiIconButton-root{
  /* display: none; */
  opacity: 0;
}
.albumEnable .playTrack > div{
  /* display: none; */
  opacity: 0;
}
.playTrack {
  position: absolute;
  top:8px;
  margin: 0 auto;
}

.MuiTableRow-hover:hover .albumEnable  .MuiAvatar-root{
  display: none;
}

.MuiTableRow-hover:hover .albumEnable .playTrack > div,
.MuiTableRow-hover:hover .albumEnable .MuiIconButton-root,
.albumEnable .MuiIconButton-root.Mui-focusVisible,
.albumEnable .MuiIconButton-root.Mui-focusVisible,
.albumEnable .MuiIconButton-root.Mui-focusVisible{
  display: inline-flex;
  opacity: 1!important;
}
 
.MuiTableRow-hover:active .albumEnable > svg,
.MuiTableRow-hover:focus .albumEnable > svg
.MuiTableRow-hover:hover .albumEnable > svg{
  /* display:none; */
  opacity: 1;
}
.albumEnable > svg{
  color: #04923F !important;
    position: relative !important;
}
.MuiDialogActions-spacing .MuiButton-root,
.MuiDialogActions-spacing .Mui-focusVisible span{
  text-transform: none !important;
}

.groupedTracksContainer{
  max-width:1060px;
}
.groupedTracksContainer .MuiTablePagination-selectRoot {
  margin-right: 8px!important;
}
.groupedTracksContainer  .MuiAutocomplete-inputRoot{
width:60% !important;
}
.groupedTracksContainer  .MuiPaginationItem-root{
  min-width: 26px!important;
  height: 26px!important;
}
.albumIconStyle button{
  color: #15a350;
}
.groupedTracksContainer .MuiTableContainer-root{
  height:400px;
}

.adminOverride h2{
  font-weight: 400;
  font-size: 16px;
}

.adminOverride .MuiFormGroup-root { 
  padding: 10px 30px;
}

.adminOverride .MuiDialogTitle-root{
    padding:10px 20px;
    border-bottom: 1px solid #616161;
}
.adminOverride .MuiDialogTitle-root svg{
  vertical-align: middle;
  font-size: 24px;
  margin-right: 12px;
}
.adminOverride .MuiDialogTitle-root svg path{
  stroke:#fff;
}
.adminOverride .MuiDialogActions-spacing button{
  margin-right: 0;
  margin-left: 10px;
}
.adminOverride .MuiDialog-paperWidthSm {
  max-width: 450px;
  width:450px;
  border: 1px solid #444;
}
.adminOverride .MuiDialogActions-root{
  font-size: 1em;
  padding: 12px 10px;
  border-top: 1px solid #464646;
  background: #2a2a2a;
}
.adminOverride .MuiDialogContent-root{
  background: #151414;
}
.adminOverride table{
  font-size: 12px;  
  width: 100%;
}
.adminOverride table thead th{
  background: #1f1b1b;
  padding: 10px 15px;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
  font-size: 11px;
  border-bottom: 1px solid #515151;
}
.adminOverride  .MuiDialogContent-dividers {
  padding: 0px 0px 16px 0px;
  border:none;
  min-height: 300px;
  overflow: auto;
  background: #2a2a2a;
  max-height: 300px;
}
.adminOverride  .MuiDialogContent-dividers  .MuiCircularProgress-colorPrimary {
  color: green;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.adminOverride table tr td {
  border-bottom: 1px solid #636363;
  padding: 7px 15px;
  line-height: 1.4;
  background: #000;
}
.adminOverride .MuiFormControlLabel-label{
   font-size: 12px;  
}
.adminOverride  table tr, .adminOverride table td,.adminOverride table th{
  border:0;
}
.MuiPickersCalendar-transitionContainer .MuiPickersDay-daySelected span {
  color: #fff !important;
}

.MuiPickersDay-current {
  color: #ffc100 !important;
}
.clearanceFormMainWrapper {
  display: flex;
}
.clearanceFormAppBar {
  display: flex;
}
 
span.string-value {
  word-break: break-word;
}
.variable-row span,
span.string-value span{
  word-break: break-word;
}


.NewStarbutton{
  background: rgba(0,0,0,0.5);
  border-radius: 50px;
  width: 20px;
  height: 20px;
  padding: 5px;
  text-align: center;
}
.NewStarbutton svg{
  font-size: 18px;
}
.ColChartIndicator svg{
  font-size: 15px;
}
.compact-picker span div:hover {
  box-shadow: rgb(221 221 221) 0px 0px 0px 1px;
}
.compact-picker  div  span div:focus,
.compact-picker  div  span div:active,
.compact-picker  div  span div:hover{
  border:1px solid #000;
}
.compact-picker  div  span div{
  border:1px solid transparent;
}
.MuiButtonBase-root:hover > .MuiTouchRipple-root{
  background-color: rgba(255, 255, 255, 0.2);
}
.MuiMenu-list .MuiButtonBase-root:hover > .MuiTouchRipple-root{
  background-color: transparent;
}
.react-tel-input .country-list .country .dial-code,
.react-tel-input .country-list .country-name{
  font-size: 0.8em !important;
}
.react-tel-input .country-list .country .dial-code {
  color: #a3a1a1!important;
}

.clearancePopup .MuiDialog-paper{
  min-width: 800px;
}
.clearancePopup .MuiDialog-paper h2{
  font-size: 1em;
}
.clearancePopup   .MuiDialog-paperScrollPaper,
.clearancePopup > .MuiPaper-rounded{
  height: 650px;
}
 
.FlexPlaylist{
  display: flex;
  align-items: center;
}
.FlexPlaylist strong{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.innerStatus{
  min-width: 90px !important;
}

span.soundMouseText {
  display: block;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 10px;
}

.boxRelative{
  position: relative;
  margin-bottom: 10px;
}
.trackAccTitle{
  padding: 0;
  margin: 0;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiSlider-root{
  color: #0c853e  !important;
}

.filterOption{
  font-size: 18px;
  margin-right: 5px!important;
}
.invalidFormate,
.invalidFormate .MuiOutlinedInput-root input{  
    background: #8d3a3a;
}
.invalidFormate .MuiFormHelperText-root{
  font-size: 11px;
    margin: 0;
    padding-left: 9px;
}
.MuiGrid-root .invalidFormate  .MuiInputLabel-shrink {
  top: 1px !important;
  left: 10px !important;
}
.MuiGrid-root .invalidFormate input:-webkit-autofill{
  background: #8d3a3a !important;
  box-shadow: 0 0 0 30px #8d3a3a inset !important;
  -webkit-box-shadow: 0 0 0 30px #8d3a3a inset !important;
}
.advActiveFilterNumBetween .searchHighlighter:first-child input{
    padding-right: 10px !important;
}
.advActiveFilterNumBetween  .MuiFormControl-root:nth-child(2) label{
  left:0px !important;
}
.advActiveFilterNumBetween .searchHighlighter:nth-child(2) input{
  padding-left: 10px !important;
}
.DateRangeButton .searchHighlighter:nth-child(2) input{
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.DateRangeButton .searchHighlighter:nth-child(1) input{
  padding-right: 10px !important;
}
/* Range Calendar */
.rdrDateDisplayItemActive input {
  color: #ffffff!important;
}
.rdrDateDisplayItem {
  background-color: #424242!important;
}
.rdrDateDisplayItemActive {
  border-color: #1fbb62!important;
}
.rdrDateDisplayWrapper {
  background-color: #424242!important;
}
.MuiDialogActions-spacing {
  /* background: #303030;
  padding: 20px 0!important;
  margin: 0 !important; */
}
.rdrDayHovered,
.rdrDayHovered .rdrInRange,
.rdrDayHovered  .rdrEndEdge,
.rdrDayHovered  .rdrStartEdge,
.rdrDayHovered  .rdrStartPreview{
  color: #1fbb62!important;
}
.rdrYearPicker option,
.rdrMonthPicker option{
  background-color: #303030!important;
  text-align: left;
}
.rdrCalendarWrapper{
  background: #424242 !important;
  color: #ffffff!important;
}
.rdrMonthAndYearPickers select{
  color: #ffffff !important;
  border: 1px solid #7c7c7c !important;
}
.rdrDayNumber span {
  color: #ffffff!important;
  font-size: 0.875rem!important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
.rdrInRange,
.rdrEndEdge,
.rdrStartEdge{
  background:#169e4f!important;
}
/* Start Media Query */

@media screen 
and (min-width: 1600px) 
  and (max-width: 1980px) { 
    .clearanceFormAppBar .MuiButton-label{
      white-space: nowrap;
    }
    .globalPopups > div{
      width: 100% ;
    }
}
@media screen 
and (min-width: 1366px) 
  and (max-width: 1600px) { 
    .clearanceFormAppBar .MuiButton-label{
      white-space: nowrap;
    }
    .resultViewsettingDropdown > div {
      width: 100px ;
      overflow: hidden;
      text-overflow: ellipsis;
  }

    .globalPopups > div{
      width: 100% ;
    }
}

@media screen 
and (min-width: 1140px) 
  and (max-width: 1366px)  { 
    .smallDevices {
      overflow: hidden !important;
      width: 100% !important;
      text-align: center !important;
  }
    .globalPopups > div{
      width: 100% ;
    } 
    .maxWidthSpan{
      max-width: 110px !important;
      display: block;
    }
}


@media screen 
and (min-width: 980) 
  and (max-width: 1140px)  { 
    .smallDevices {
      overflow: hidden !important;
      width: 100% !important;
  }
    .globalPopups > div{
      width: 100% ;
    } 
}
@media screen 
and (min-width: 680px) 
  and (max-width: 1140px)  { 
     
    .globalPopups > div{
      width: 100% ;
    } 
}




@media screen 
and (min-device-width: 1280px) 
  and (max-device-width: 1980px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

 

    .advancedSearchforLaptop  > div > div{
      /* min-width: 250px; */
      width: -webkit-fill-available;
    }
    .albumSearchforLaptop  > div > div{
      min-width: 250px;
     
    }
  
  
   
}

@media screen 
and (min-device-width: 1650px) 
  and (max-device-width: 1980px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
     
    .searchButtonsSet .searchDropdownforSmallDev > div{
      max-width: 100px;
    }
    .allDropdownSpan > div{
      max-width:100px;
    }

    .advancedSearchforLaptop  > div > div{
      /* min-width:570px; */
      width: -webkit-fill-available;
    }
    .albumSearchforLaptop  > div > div{
      min-width: 500px;
     
    }

    .advancedSearchforLaptop  > div > .MuiTextField-root{
      min-width: 500px;
 }

}

@media screen 
and (min-device-width: 1650px) 
  and (max-device-width: 1880px) 
  and (-webkit-min-device-pixel-ratio: 1) {
 
  
}

@media screen 
and (min-width: 1350px) 
  and (max-width: 1650px)   {      
    .advancedSearchforLaptop  > div > div{
      /* min-width: 400px; */
      width:100%;
    }
    .albumSearchforLaptop  > div > div{
      min-width: 380px;
      width:100%;
    }
    .advancedSearchforLaptop  > div > .MuiTextField-root{
      min-width: 310px;
 }

.searchButtonsSet .searchDropdownforSmallDev > div{
  max-width: 100px;
}
.allDropdownSpan > div{
  max-width: 60px;
}
.promoSearchforLaptop .searchButtonsSet > div{
  max-width: inherit;
  width: inherit;
}
 
}

@media screen 
and (min-width: 1000px) 
  and (max-width: 1100px)  { 
    .smallDevices {
      overflow: hidden !important;
      width: 100% !important;
  }
    .resultViewsettingDropdown > div {
      width: 56px; 
      overflow: hidden;
      text-overflow: ellipsis;
  }
  /* .clearanceFormAppBar > button{ 
    width: 34px !important;
    min-width: inherit;
    font-size: 0!important;
    padding: 5px !important;
    
  }
  .clearanceFormAppBar > button  svg{
    margin-right: 0!important;
  } */

  }
  @media screen 
  and (min-width: 1000px) 
    and (max-width: 1340px)  { 
      .bbcLogoHeader{
        margin: 0 !important;
      }
      
.clearanceFormMainWrapper .smallDeviceInput{
  min-width: 230px !important;
}
      .clearanceFormMainWrapper{
        display: block;
      }
      .clearanceFormAppBar {
        margin-top: 8px;
    }
    .clearanceFormAppBar .MuiButton-label{
      white-space: nowrap;
    }
    .buttonTextHide{
      font-size: 0 !important;
      min-width: inherit !important;
      width: 31px   !important;
      text-align: center !important;
    }
    .buttonTextHide svg{
      margin: 0 !important;
    }
    }

@media screen 
and (min-width: 1000px) 
  and (max-width: 1560px)  { 

.clearanceFormMainWrapper .smallDeviceInput{
  min-width: 360px;
}

    .clearanceFormAppBar .MuiButton-label{
      white-space: nowrap;
    }
    .clearanceFormMainWrapper{
      display: block;
    }
    .clearanceFormAppBar {
      margin-top: 8px;
  }
    .avatarContainer{
      width: 100% !important;
    }
    .MuiDrawer-paperAnchorRight {
      /* width: calc(30% - 0.2px) !important; */
      min-width: auto !important;
  }
    .searchButtonsSet .searchDropdownforSmallDev > div{
      max-width: 170px;
    }
    .allDropdownSpan > div{
      max-width: 200px;
    }
    .resultViewsettingDropdown > div {
      /* width: 56px; */
      overflow: hidden;
      text-overflow: ellipsis;
  }
    .promoSearchforLaptop,
    .advancedSearchforLaptop{
      display: block;
    }
    .albumSearchforLaptop{
      display: block;
    }
    .promoSearchforLaptop > div,
    .albumSearchforLaptop > div,
    .advancedSearchforLaptop > div{
      display: block;
    }
    .searchButtonsSet{
      margin-top: 10px;
    }
 
   .leftMarginRemove.searchButtonsSet{
      margin-left:0 !important;
      text-align: right;
    }

    .searchButtonsSet{
      margin-left:-5px !important;
    }
    /* .promoSearchforLaptop > div > div, */
    .advancedSearchforLaptop  > div > div{
      /* min-width: 250px; */
      width: -webkit-fill-available;
    }
    .albumSearchforLaptop  > div > div{
      min-width: 420px;
      width: -webkit-fill-available;
    }
    .advancedSearchforLaptop  > div > .MuiTextField-root{
      min-width: 310px;
 }
 .searchButtonsSet > div {
  max-width: 180px;
}

.promoSearchforLaptop .searchButtonsSet > div{
  max-width: inherit;
  width: inherit;
}
.promoSearchforLaptop  .searchButtonsSet > div > div {
  margin-right: 10px;
  margin-left: 0;
  width: auto;
}
}


@media screen 
and (min-width: 1300px) 
  and (max-width: 1600px)  { 
    .resultViewsettingDropdown > div {
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  }
@media screen 
and (min-width: 600px) 
  and (max-width: 1000px)  { 
   
 
    .MuiDrawer-paperAnchorRight {
      min-width: auto !important;
  }
    .InnerthreeDiv{
      display: block;
      width: 100%;
    } 
    .InnerthreeDiv span{
      margin: 0;
    }
    .InnerthreeDiv > span > div{
      margin: 0;
    }
    .promoSearchforLaptop,
    .advancedSearchforLaptop{
      display: block;
    }
    .albumSearchforLaptop{
      display: block;
    }
    .promoSearchforLaptop > div,
    .albumSearchforLaptop > div,
    .advancedSearchforLaptop > div{
      display: block;
    }
    .searchButtonsSet{
      margin-top: 10px;
    }

    .leftMarginRemove.searchButtonsSet{
      margin-left:0 !important;
      text-align: right;
    }
    .searchButtonsSet{
      margin-left:-5px !important;
    }
    /* .promoSearchforLaptop > div > div, */
    .advancedSearchforLaptop  > div > div{
      /* min-width: 280px; */
      width: -webkit-fill-available;
    }
     
    .albumSearchforLaptop  > div > div{
      min-width: 420px;
      width: -webkit-fill-available;
    }
    .advancedSearchforLaptop  > div > .MuiTextField-root{
      min-width: 310px;
 }
 .searchButtonsSet > div {
  max-width: 180px;
}

.promoSearchforLaptop .searchButtonsSet > div{
  max-width: inherit;
  width: inherit;
}
.promoSearchforLaptop  .searchButtonsSet > div > div {
  margin-right: 10px;
  margin-left: 0;
  width: auto;
}
}


@media screen  and (min-device-width: 1260px)  {  
    .MuiGrid-grid-sm-5 .refreshforLaptop{
      margin: 0px;
      display: inline-block;
    }
     
     

}

@media screen 
and (min-width: 900px) 
and (max-width: 1125px)  {
.HeaderMainWrap > div{
  padding-right: 0 !important;
}

  
  .MuiDrawer-paperAnchorRight {
    /* width: calc(30% - 0.2px) !important; */
    min-width: auto !important;
}
  .InnerthreeDiv{
    display: block;
    width: 100%;
  }
  .InnerthreeDiv span{
    margin: 0;
  }
  .InnerthreeDiv > span > div{
    margin: 0;
  }
    .MuiGrid-grid-sm-5 .refreshforLaptop{
      margin: 10px 5px 0px 5px;
    }
   
  
}
@media screen 
and (min-width: 950px) 
and (max-width: 1180px)  {


  .BBCLogoInside{
    display: none;
  }
  .bbcLogoHeader{
    margin: 0 !important;
  }
  .avatarContainer{
    width: 100% !important;
  } 
  .clearanceFormMainWrapper{
    display: block;
  }
  .clearanceFormAppBar {
    margin-top: 8px;
}
  .InnerthreeDiv{
    display: block;
    width: 100%;
  }
  .InnerthreeDiv span{
    margin: 0;
  }
  .InnerthreeDiv > span > div{
    margin: 0;
    width: -webkit-fill-available;
  }
  .searchButtonsSet{
    margin-top: 10px;
  }

  .leftMarginRemove.searchButtonsSet{
    margin-left:0 !important;
    text-align: right;
  }
  .searchButtonsSet{
    margin-left:-5px !important;
  }
  .advancedSearchforLaptop  > div:first-child > div{
    min-width: 360px;
    width: -webkit-fill-available;
  }
  .albumSearchforLaptop  > div > div{
    min-width: 360px;
    width: -webkit-fill-available;
  }
  .MuiGrid-grid-sm-5  .AlbumSearchforLaptop{
    /* margin: 10px 0px 0px 5px !important; */
    display: inline-block;
  }
    
}



@media screen 
  and (min-device-width: 1280px) 
  and (max-device-width: 1550px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

    .maxWidthSpan{
      max-width: 110px !important;
      display: block;
    }
    .searchButtonsSet,
    .advancedSearchforLaptop .blockForSmallDevices{
      max-width: inherit !important;
    }
    .MuiGrid-grid-sm-5 .refreshforLaptop{
    
      display: inline-block;
    }
    .advancedSearchforLaptop  > div > div{
       width: -webkit-fill-available;
    }
    .MuiGrid-grid-sm-5  .AlbumSearchforLaptop{
     
      display: inline-block;
    }
   
}

@media screen 
and (min-width: 1280px) 
and (max-width: 1320px)    { 
    .MuiGrid-grid-sm-5  .AlbumSearchforLaptop{
      /* margin: 10px 0px 0px 5px !important; */
      display: inline-block;
    }
}


@media screen 
  and (max-device-width: 1680px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .MuiGrid-grid-sm-5 .refreshforLaptop{
      display: inline-block;
      /* margin: 10px 5px 0px 5px; */
    }
    .resultsForLaptop > div{
      padding-left: 80px !important;
    }
    .resTableHeight{
      height: calc(100% - 178px);
    }
 
}
@media screen 
  and (min-width: 1745px)  { 
    .resTableHeight{
      height: calc(100% - 180px) !important;
    }
 
}
@media screen 
  and (min-width: 2133.33px)  { 
    .resTableHeight{
      height: calc(100% - 180px) !important;
    }
 
}
@media screen 
  and (min-width: 2880px)  { 
    .resTableHeight{
      height: calc(100% - 183px) !important;
    }
 
}
@media screen 
  and (min-width: 3840px)  { 
    .resTableHeight{
      height: calc(100% - 188px) !important;
    }
 
}


@media screen 
and (min-device-width: 1181px) 
  and (max-device-width: 1280px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .MuiGrid-grid-sm-5 .refreshforLaptop{
      display: inline-block;
      /* margin:10px 5px 0px 5px; */
    }
    .resultsForLaptop > div{
      padding-left: 80px !important;
    }

  
}

@media screen 
  and (max-device-width: 1280px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .forLaptopMainSearch{
      max-width: 110px;
    }
    .resultsForLaptop > div{
      padding-left: 80px !important;
    }
    
   
}


@media screen 
  and (min-device-width: 960px) 
  and (max-device-width: 1180px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
 
.BBCLogoInside{
  display: none;
}
     
    .MuiGrid-grid-sm-5 .refreshforLaptop{
      
      display: inline-block;
    }
    .resultsForLaptop > div{
      padding-left: 80px !important;
    }
    
    
}

